/**
 *
 * Fulfillment Selectors
 *
 */

import { createSelector } from 'reselect';

const getFulfillmentRoot = state => state.fulfillment;

export const isFulfillmentModalOpen = createSelector(
  getFulfillmentRoot,
  fulfillment => fulfillment && fulfillment.isModalOpen,
);

export const getFulfillmentError = createSelector(
  getFulfillmentRoot,
  fulfillment => fulfillment && fulfillment.error,
);

export const getFulfillmentErrorBody = createSelector(
  getFulfillmentError,
  error => error?.error?.errorBody,
);

export const getFulfillmentErrorAddressId = createSelector(
  getFulfillmentError,
  error => error?.userDeliveryAddressId,
);

export const getFulfillmentLoading = createSelector(
  getFulfillmentRoot,
  fulfillment => fulfillment && fulfillment.isLoading,
);

export const getFulfillmentToggling = createSelector(
  getFulfillmentRoot,
  fulfillment => fulfillment && fulfillment.isToggling,
);
