import { useSelector } from 'react-redux';
import {
  getIsMerchantDisplayModeDelivery,
  getMerchantSupportsDelivery,
} from '@artemis/store/merchant/selectors';
import { HIDE_PICKUP_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';

const useIsDeliveryOnly = () => {
  const hidePickup = useQueryCheck(HIDE_PICKUP_PARAM);

  const isMerchantDisplayModeDelivery = useSelector(
    getIsMerchantDisplayModeDelivery,
  );
  const supportsDelivery = useSelector(getMerchantSupportsDelivery);

  return (
    (hidePickup || isMerchantDisplayModeDelivery) && supportsDelivery === true
  );
};

export default useIsDeliveryOnly;
