import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';

const ScheduledForTime = ({ scheduledForTime, orderLaterOnly }) => {
  if (!scheduledForTime || !scheduledForTime.date) {
    if (orderLaterOnly)
      return <FormattedMessage entry="menu.schedule.scheduleOrder" />;
    return <FormattedMessage entry="menu.now" />;
  }
  const { date, isToday, isTomorrow } = scheduledForTime;
  if (isToday) {
    return <FormattedMessage entry="global.todayDate" values={{ date }} />;
  }
  if (isTomorrow) {
    return <FormattedMessage entry="global.tomorrowDate" values={{ date }} />;
  }
  return date;
};

ScheduledForTime.propTypes = {
  scheduledForTime: PropTypes.shape({
    date: PropTypes.string,
    isToday: PropTypes.bool,
    isTomorrow: PropTypes.bool,
  }),
  orderLaterOnly: PropTypes.bool,
};

export default ScheduledForTime;
