import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { associateFulfillment } from '@artemis/store/cart/slice';
import {
  getCartFulfillmentId,
  getCartFulfillmentType,
  getCartScheduledForTime,
} from '@artemis/store/cart/selectors';
import {
  getFulfillmentId,
  getFulfillmentMerchantScheduledTime,
  getIsFulfillmentLoaded,
  getIsInitialized,
} from '@artemis/store/groupOrder/selectors';
import { setScheduledForTime } from '@artemis/store/scheduling/slice';
import { getMerchantId } from '@artemis/store/merchant/selectors';
import { FULFILLMENT_TYPE } from '@artemis/utils/constants';
import { setFulfillment } from '@artemis/store/fulfillment/slice';
import { IN_STORE_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';

// TODO: move this somewhere else, since it's no longer specific to group ordering
// TODO(IE-2686): add test cases for when user is in store
const useConfigureCartForCurrentContext = () => {
  const dispatch = useDispatch();
  const isInStore = useQueryCheck(IN_STORE_PARAM);

  const initialized = useSelector(getIsInitialized);
  const merchantId = useSelector(getMerchantId);
  const fulfillmentId = useSelector(getFulfillmentId);
  const cartFulfillmentId = useSelector(getCartFulfillmentId);
  const cartFulfillmentType = useSelector(getCartFulfillmentType);
  const cartScheduledTime = useSelector(getCartScheduledForTime);
  const fulfillmentScheduledTime = useSelector(
    getFulfillmentMerchantScheduledTime,
  );
  const isFulfillmentLoaded = useSelector(getIsFulfillmentLoaded);

  const shouldCheckCart = initialized;

  // Update cart if it does not match current fulfillment
  useEffect(() => {
    if (!shouldCheckCart) {
      return;
    }

    if (fulfillmentId && cartFulfillmentId !== fulfillmentId) {
      dispatch(
        associateFulfillment({
          merchantId,
          fulfillmentId,
        }),
      );
    }

    if (!fulfillmentId && cartFulfillmentId) {
      dispatch(
        associateFulfillment({
          merchantId,
          fulfillmentId: '',
        }),
      );
    }
  }, [shouldCheckCart, fulfillmentId, cartFulfillmentId]);

  // Ensure fulfillment type for cart if "IN_STORE" if it is being prepared for a group order, or user is in store
  useEffect(() => {
    if (!shouldCheckCart) {
      return;
    }

    const fulfillmentTypeIsNotPickup =
      cartFulfillmentType && cartFulfillmentType !== FULFILLMENT_TYPE.IN_STORE;

    const isGroupOrder = !!fulfillmentId;

    if (
      (isGroupOrder && fulfillmentTypeIsNotPickup) ||
      (isInStore && fulfillmentTypeIsNotPickup)
    ) {
      dispatch(
        setFulfillment({
          merchantId,
          merchantFulfillmentType: FULFILLMENT_TYPE.IN_STORE,
        }),
      );
    }
  }, [shouldCheckCart, fulfillmentId, cartFulfillmentType]);

  // Update cart to match fulfillment's scheduled time
  useEffect(() => {
    if (!shouldCheckCart) {
      return;
    }

    const timesMatch =
      (!cartScheduledTime && !fulfillmentScheduledTime) ||
      cartScheduledTime === fulfillmentScheduledTime;

    if (fulfillmentId && isFulfillmentLoaded && !timesMatch) {
      dispatch(
        setScheduledForTime({
          merchantId,
          scheduledForTime: fulfillmentScheduledTime,
        }),
      );
    }
  }, [
    shouldCheckCart,
    fulfillmentId,
    isFulfillmentLoaded,
    cartScheduledTime,
    fulfillmentScheduledTime,
  ]);
};

export default useConfigureCartForCurrentContext;
