import React from 'react';
import PropTypes from 'prop-types';
import {
  GreaterMinutes,
  HoursAndMinutes,
  Minutes,
  Separator,
} from './components';

const GREATER_LIMIT = 60;

export const TimeRange = ({ min, max, isShort = false }) => {
  if (min == null || max == null || min > max) return null;

  if (isShort) {
    // ex: 60+ mins
    if (min >= GREATER_LIMIT) return <GreaterMinutes />;
    // ex: 25 mins
    if (min === max) return <Minutes value={max} />;
    // ex: 25 - 60+ mins OR 25 - 45 mins
    return (
      <>
        {min}
        <Separator />
        {max >= GREATER_LIMIT ? <GreaterMinutes /> : <Minutes value={max} />}
      </>
    );
  }

  // ex: 25 mins OR 1 hr 15 mins
  if (min === max) {
    return <HoursAndMinutes value={max} />;
  }
  // ex: 25 mins - 1 hr 15 mins OR
  //     25 - 45 mins OR
  //     1 hr 5 mins - 1 hr 15 mins
  return (
    <>
      {max >= GREATER_LIMIT ? <HoursAndMinutes value={min} /> : min}
      <Separator />
      <HoursAndMinutes value={max} />
    </>
  );
};

TimeRange.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  isShort: PropTypes.bool,
};
