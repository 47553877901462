import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { useRemoteConfig } from '@artemis/integrations/remoteConfig';
import { loadDeliveryAddresses } from '@artemis/store/deliveryAddresses/slice';
import { getMerchantSupportsDelivery } from '@artemis/store/merchant/selectors';
import { getFulfillmentSupportsDelivery } from '@artemis/store/groupOrder/selectors';
import useIsSAPWorkspace from '@artemis/utils/query/useIsSAPWorkspace';

export const useLoadDeliveryAddresses = ({ forGroupOrder = false } = {}) => {
  const dispatch = useDispatch();
  const { authenticated, initialized } = useContext(AuthenticationContext);
  const { configLoaded } = useRemoteConfig();
  const isFromSapWorkspaceList = useIsSAPWorkspace();

  const supportsRegularDelivery = useSelector(getMerchantSupportsDelivery);
  const supportsGroupOrderDelivery = useSelector(
    getFulfillmentSupportsDelivery,
  );

  const supportsDelivery =
    !isFromSapWorkspaceList &&
    (forGroupOrder ? supportsGroupOrderDelivery : supportsRegularDelivery);

  useEffect(() => {
    if (initialized && configLoaded) {
      const shouldLoad = supportsDelivery && authenticated;
      dispatch(loadDeliveryAddresses(shouldLoad));
    }
  }, [initialized, configLoaded, supportsDelivery, authenticated]);
};
