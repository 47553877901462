import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { FORMAT, formatDate, parseUTC } from '@artemis/utils/time-format';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';

const Date = styled.span`
  white-space: nowrap;
  font-size: inherit;
  font-weight: inherit;
`;

const FriendlyDateMessage = ({
  date,
  timeZone,
  todayEntry,
  tomorrowEntry,
  otherEntry,
}) => {
  const parsedDate = parseUTC(date, timeZone);

  const targetDay = parsedDate.startOf('day');
  const currentDay = dayjs().tz(timeZone).startOf('day');
  const daysAhead = targetDay.diff(currentDay, 'day');

  if (daysAhead === 0) {
    return (
      <FormattedMessage
        entry={todayEntry}
        values={{
          time: formatDate(parsedDate, FORMAT.DAY_OF_WEEK_AND_TIME),
          date: text => <Date>{text}</Date>,
        }}
      />
    );
  }
  if (daysAhead === 1) {
    return (
      <FormattedMessage
        entry={tomorrowEntry}
        values={{
          time: formatDate(parsedDate, FORMAT.DAY_OF_WEEK_AND_TIME),
          date: text => <Date>{text}</Date>,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      entry={otherEntry}
      values={{
        time: formatDate(parsedDate, FORMAT.DAY_OF_WEEK_DATE_AND_TIME),
        date: text => <Date>{text}</Date>,
      }}
    />
  );
};

FriendlyDateMessage.propTypes = {
  date: PropTypes.string,
  timeZone: PropTypes.string,
  todayEntry: PropTypes.string,
  tomorrowEntry: PropTypes.string,
  otherEntry: PropTypes.string,
};

export default FriendlyDateMessage;
