import Cookies from '@artemis/utils/cookies';

const getMenuLanguage = supportedLocales => {
  const userLanguage = Cookies.get('rt-lang-menu');
  const rtLanguage = Cookies.get('rt-lang');

  const displayedLanguage = supportedLocales?.find(l =>
    l.startsWith(userLanguage),
  )
    ? userLanguage
    : rtLanguage;
  return displayedLanguage;
};

export default getMenuLanguage;
