import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  beginPollingForFulfillmentUpdates,
  loadFulfillment,
  findFulfillments,
  findFulfillmentsSuccess,
} from '@artemis/store/groupOrder/slice';

import { getFulfillmentId } from '@artemis/store/groupOrder/selectors';
import { FULFILLMENT_STATE } from '@artemis/utils/constants';
import useReloadFulfillmentAfterDeadline from './useReloadFulfillmentAfterDeadline';

/**
 * Checks if a user is currently in a fulfillment based on
 * merchantId, orderId, and/or orderToken.
 *
 * If the user is in a fulfillment it will be loaded.
 */
const useFindAndLoadFulfillment = ({
  merchantId,
  orderId,
  orderToken,
  includeOrderId = false,
  guestStatus,
}) => {
  const dispatch = useDispatch();
  const { authenticated, initialized } = useContext(AuthenticationContext);
  const fulfillmentId = useSelector(getFulfillmentId);

  // Reload fulfillment after the deadline for placing orders (`orderByTime`) passes
  useReloadFulfillmentAfterDeadline();

  // Check if user is in a fulfillment
  useEffect(() => {
    if (initialized) {
      if (orderToken) {
        dispatch(
          findFulfillments({
            orderToken,
            guestStatus,
          }),
        );
      } else if (authenticated && orderId) {
        dispatch(
          findFulfillments({
            orderId,
            guestStatus,
          }),
        );
      } else if (authenticated && merchantId) {
        dispatch(
          findFulfillments({
            merchantId,
            fulfillmentState: FULFILLMENT_STATE.OPEN,
            guestStatus,
          }),
        );
      } else if (!authenticated) {
        // Do not try to load any fulfillments, but update fulfillment state to "initialized"
        dispatch(findFulfillmentsSuccess({ data: [] }));
      }
    }
  }, [
    initialized,
    authenticated,
    merchantId,
    orderId,
    orderToken,
    guestStatus,
  ]);

  // Load fulfillment and begin polling if one was found
  useEffect(() => {
    if (fulfillmentId && authenticated) {
      dispatch(loadFulfillment({ fulfillmentId, includeOrderId }));
      dispatch(beginPollingForFulfillmentUpdates());
    }
  }, [fulfillmentId, authenticated]);
};

export default useFindAndLoadFulfillment;
