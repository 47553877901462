import { loadFulfillment } from '@artemis/store/groupOrder/slice';
import {
  getFulfillmentId,
  getOrderByTime,
} from '@artemis/store/groupOrder/selectors';
import { parseUTC } from '@artemis/utils/time-format';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'react-use';

const INTERVAL_MS = 3000; // 3 seconds

const useReloadFulfillmentAfterDeadline = () => {
  const dispatch = useDispatch();
  const fulfillmentId = useSelector(getFulfillmentId);
  const orderByTime = useSelector(getOrderByTime);

  const [hasDeadlinePassed, setHasDeadlinePassed] = useState(false);

  // Check if deadline for placing an order has passed
  useInterval(() => {
    if (fulfillmentId && orderByTime && !hasDeadlinePassed) {
      const deadline = parseUTC(orderByTime);
      const now = dayjs();

      if (now.isAfter(deadline)) {
        setHasDeadlinePassed(true);
      }
    }
  }, INTERVAL_MS);

  // Reload fulfillment when deadline passed
  useEffect(() => {
    if (fulfillmentId && hasDeadlinePassed) {
      dispatch(loadFulfillment({ fulfillmentId }));
    }
  }, [fulfillmentId, hasDeadlinePassed]);
};

export default useReloadFulfillmentAfterDeadline;
