import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  getIsCurrentUserAdmin,
  getFulfillmentId,
} from '@artemis/store/groupOrder/selectors';
import { setDetailsModal } from '@artemis/store/groupOrder/slice';
import { getOrderSourceOrigin } from '@artemis/store/order/selectors';
import { FROM_PARAM } from '@artemis/utils/query/constants';
import usePersistedQuery from '@artemis/utils/query/usePersistedQuery';

const Button = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.palette.primary};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const ViewGroupOrder = ({ from, ...props }) => {
  const dispatch = useDispatch();
  const persistedQuery = usePersistedQuery();
  const isAdmin = useSelector(getIsCurrentUserAdmin);
  const id = useSelector(getFulfillmentId);
  const origin = useSelector(getOrderSourceOrigin);

  const onViewGroupOrder = useCallback(() => {
    if (isAdmin) {
      const otherParams = persistedQuery.replace('?', '&');
      window.location.assign(
        `${origin}/order/fulfillments/${id}/status?${FROM_PARAM}=${from}${otherParams}`,
      );
    } else {
      dispatch(setDetailsModal({ isOpen: true }));
    }
  }, [isAdmin, id]);

  return (
    <Button onClick={onViewGroupOrder} {...props}>
      <FormattedMessage entry="groupOrder.view" />
    </Button>
  );
};

ViewGroupOrder.propTypes = {
  from: PropTypes.string,
};

export default ViewGroupOrder;
