import { useRouter } from 'next/router';
import { WORKSPACE_ID_PARAM } from './constants';
import { isSAPWorkspaceId } from '../sapHelpers';

/**
 * @returns {bool} `true` when coming from the SAP workspace.
 * Quick hack for disabling delivery for SAP workspace list
 */
const useIsSAPWorkspace = () => {
  const { query } = useRouter();
  return isSAPWorkspaceId(query[WORKSPACE_ID_PARAM]);
};

export default useIsSAPWorkspace;
