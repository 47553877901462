import { useSelector } from 'react-redux';
import { getMerchantId } from '@artemis/store/merchant/selectors';
import { useEffect } from 'react';
import Cookies from '@artemis/utils/cookies';

/**
 * The cookie name for the Google Food conversion token.
 */
export const GFO_CONVERSION_TRACKING_COOKIE_NAME = 'rwg_token';

/**
 * The name of the cookie which stores the merchant id the user landed
 * on from GFO.
 */
export const GFO_CONVERSION_MERCHANT_ID_COOKIE_NAME = '_gfo_merchant_id';

/**
 * The test value for Sandbox and Dev which Google accepts.
 */
export const GFO_CONVERSION_TRACKING_COOKIE_EXAMPLE =
  'ADQ7psRE9YyDSVR6YpfD-fYdxoFYVKS1xeTvXdSxqF8a3bnk0W62eMEnUjoNPwjhNHG0elwBnM1awTjr9vXET8yOowCeuODjwA==';

const GFO_PARTNER_ID = 20002860;

/**
 * Looks for the GFO query param and stores it in the cookie if
 * present.
 */
export function useCollectGfoConversionCookie() {
  const merchantId = useSelector(getMerchantId);

  /**
   * This has a dependency on the `merchantId` because we need to store it
   * in a cookie but we short circuit if the query param is not present, so
   * if the user presses back and goes to a different merchant we do not
   * have the query param so we would not write the cookie again. So we
   * always have the correct GFO landed `merchantId` in the cookie.
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const rwgToken = params.get(GFO_CONVERSION_TRACKING_COOKIE_NAME);

    // if we have the param in the url then set the cookie for the token and merchant id
    if (rwgToken) {
      Cookies.set(GFO_CONVERSION_TRACKING_COOKIE_NAME, rwgToken, {
        expires: 30,
        domain: process.env.RT_SHARED_COOKIE_DOMAIN,
        path: '/',
      });

      Cookies.set(GFO_CONVERSION_MERCHANT_ID_COOKIE_NAME, merchantId, {
        expires: 30,
        domain: process.env.RT_SHARED_COOKIE_DOMAIN,
        path: '/',
      });
    }
  }, [merchantId]);
}

/**
 * An order has been placed so we should check if we have a token to
 * tell us this was connected to GFO in some way. Take the current merchant id
 * to compare it to the one the user landed on from GFO.
 *
 * @returns {boolean} - whether the event was fired
 */
export function registerGfoConversionEvent(currentMerchantId) {
  const rwgTokenVal = Cookies.get(GFO_CONVERSION_TRACKING_COOKIE_NAME);

  if (rwgTokenVal) {
    const merchantId = Cookies.get(GFO_CONVERSION_MERCHANT_ID_COOKIE_NAME);

    fetch(process.env.RT_GFO_CONVERSION_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        conversion_partner_id: GFO_PARTNER_ID,
        rwg_token:
          process.env.RT_ENVIRONMENT === 'production'
            ? rwgTokenVal
            : GFO_CONVERSION_TRACKING_COOKIE_EXAMPLE,
        merchant_changed: currentMerchantId === merchantId ? 2 : 1,
      }),
    });

    return true;
  }

  return false;
}
