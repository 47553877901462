import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';

export const Separator = () => (
  <FormattedMessage entry="delivery.timeRange.separator" />
);

export const Minutes = ({ value }) => (
  <FormattedMessage entry="delivery.timeRange.minutes" values={{ value }} />
);
Minutes.propTypes = { value: PropTypes.number };

export const Hours = ({ value }) => (
  <FormattedMessage entry="delivery.timeRange.hours" values={{ value }} />
);
Hours.propTypes = { value: PropTypes.number };

/**
 * @param {number} value Takes a value of minutes
 * @returns Formatted string that displays `value` in hours and minutes
 */
export const HoursAndMinutes = ({ value }) => {
  const roundedMinutes = Math.round(value);
  const numHours = Math.floor(roundedMinutes / 60);
  const numMinutes = roundedMinutes % 60;

  if (numHours === 0 && numMinutes === 0) {
    return <Minutes value={0} />;
  }

  return (
    <>
      {numHours > 0 && <Hours value={numHours} />}
      {numHours > 0 && numMinutes > 0 && ' '}
      {numMinutes > 0 && <Minutes value={numMinutes} />}
    </>
  );
};
HoursAndMinutes.propTypes = { value: PropTypes.number };

export const GreaterMinutes = ({ value = 60 }) => (
  <FormattedMessage
    entry="delivery.timeRange.greaterMinutes"
    values={{ value }}
  />
);
GreaterMinutes.propTypes = { value: PropTypes.number };
