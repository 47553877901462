import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { CalorieRangeShape } from './propTypes';

const Container = styled.span`
  color: ${props => props.theme.rtColors.black700};
  font-weight: 400;
  font-size: inherit;
`;

const CalorieRange = ({ className, range }) => {
  const minCalories = range?.minCalories;
  const maxCalories = range?.maxCalories;

  if (!minCalories && !maxCalories) {
    return null;
  }

  if (minCalories === maxCalories) {
    return (
      <Container className={className}>
        <FormattedMessage
          entry="menu.calorieCount.singleNumber"
          values={{ calories: minCalories }}
        />
      </Container>
    );
  }

  return (
    <Container className={className}>
      <FormattedMessage
        entry="menu.calorieCount.range"
        values={{ minCalories, maxCalories }}
      />
    </Container>
  );
};

CalorieRange.propTypes = {
  className: PropTypes.string,
  range: CalorieRangeShape,
};

export default CalorieRange;
