import { useSelector } from 'react-redux';
import {
  getIsMerchantDisplayModeOrderAhead,
  getMerchantSupportsScheduling,
} from '@artemis/store/merchant/selectors';
import { ORDER_LATER_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';

const useIsOrderLaterOnly = () => {
  const hasOrderLaterParam = useQueryCheck(ORDER_LATER_PARAM);

  const isMerchantDisplayModeOrderAhead = useSelector(
    getIsMerchantDisplayModeOrderAhead,
  );

  const merchantSupportsScheduling = useSelector(getMerchantSupportsScheduling);

  const orderLaterOnly = hasOrderLaterParam || isMerchantDisplayModeOrderAhead;

  return merchantSupportsScheduling && orderLaterOnly;
};

export default useIsOrderLaterOnly;
