import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { millisToFormattedTime } from '@artemis/utils/time-format';

const Container = styled.p`
  padding: 0 16px 16px 16px;
  white-space: pre-line;
  ${({ $centered }) =>
    $centered &&
    css`
      text-align: center;
    `};
`;

const AvailabilityHours = ({ availabilityHours, centered }) => {
  let result = 'Always Available';

  if (availabilityHours) {
    result = availabilityHours
      .map(({ dayOfTheWeek, availability }) => {
        const duration = availability?.durations?.[0];
        const startTime = millisToFormattedTime(duration?.startTimeMs);
        const endTime = millisToFormattedTime(duration?.endTimeMs);
        return `${dayOfTheWeek} ${startTime} - ${endTime}`;
      })
      .join(centered ? '\n' : ', ');
  }

  return (
    <Container $centered={centered}>{`Hours:${
      centered ? '\n' : ' '
    }${result}`}</Container>
  );
};

AvailabilityHours.propTypes = {
  availabilityHours: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfTheWeek: PropTypes.string,
      availability: PropTypes.shape({
        durations: PropTypes.arrayOf(
          PropTypes.shape({
            startTimeMs: PropTypes.number,
            endTimeMs: PropTypes.number,
          }),
        ),
      }),
    }),
  ),
  centered: PropTypes.bool,
};

export default AvailabilityHours;
