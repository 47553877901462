import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { getIsMerchantDisplayModeDineIn } from '@artemis/store/merchant/selectors';
import useIsDineIn from '@artemis/containers/Cart/useIsDineIn';
import {
  DINE_IN_TYPE_PARAM,
  DINE_IN_TYPES,
  DINE_IN_DESCRIPTION_PARAM,
} from '@artemis/utils/query/constants';

const Input = styled.input`
  margin-left: 10px;
  max-width: 150px;
  border: none;
  background: ${props => props.theme.palette.grey[100]};
  border-radius: 4pt;
  padding: 8px 16px;
  ${props => props.theme.isTablet`
    max-width: 344px;
  `}
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DineInInput = ({ className }) => {
  const isDineIn = useIsDineIn();
  const router = useRouter();
  const {
    [DINE_IN_DESCRIPTION_PARAM]: dineInDescription,
    [DINE_IN_TYPE_PARAM]: dineInType,
  } = router.query;
  const isMerchantDisplayModeDineIn = useSelector(
    getIsMerchantDisplayModeDineIn,
  );

  const persist = value => {
    router.push(
      {
        pathname: router.query.pathname,
        query: {
          ...router.query,
          [DINE_IN_DESCRIPTION_PARAM]: value,
        },
      },
      undefined,
      {
        replace: true,
        shallow: true,
        scroll: false,
      },
    );
  };

  // default did type to table, if you are adding additional identifiers do it here
  let didType = 'menu.dineIn.table';
  if (dineInType === DINE_IN_TYPES.ROOM) {
    didType = 'menu.dineIn.room';
  } else if (dineInType !== DINE_IN_TYPES.TABLE && dineInType) {
    didType = 'menu.dineIn.customized';
  }

  return isDineIn ? (
    <Container className={className}>
      <label htmlFor="dineIn">
        <FormattedMessage entry={didType} values={{ value: dineInType }} />
      </label>
      <Input
        id="dineIn"
        disabled={!dineInType && !isMerchantDisplayModeDineIn}
        value={dineInDescription || ''}
        onChange={e => persist(e.target.value)}
      />
    </Container>
  ) : null;
};

DineInInput.propTypes = {
  className: PropTypes.string,
};

export default DineInInput;
